import http from '../libs/http';
import tools from '../libs/tools';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Italian from '@uppy/locales/lib/it_IT';

import { Dashboard } from '@uppy/vue'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
    data(){
        return {
            uppyDashboardProps: {
                height: 250
            }
        };
    },
    components: {
        UppyDashboard: Dashboard
    },
    methods: {
        uppyGetFileType(mime){
            if (mime.indexOf('image') !== -1) {
                return tools.models.uploadFileTypes.IMAGE;
            }
            if (mime.indexOf('pdf') !== -1) {
                return tools.models.uploadFileTypes.PDF;
            }
            if (mime.indexOf('audio') !== -1) {
                return tools.models.uploadFileTypes.SOUND;
            }
            return tools.models.uploadFileTypes.UNKNOWN;
        },
        uppyGetFileExtension(file){
            const re = /(?:\.([^.]+))?$/;
            return re.exec(file)[1].toLowerCase();
        },
        getS3keyFromFile(file, targetFolder, lang){
            const ext = this.uppyGetFileExtension(file);
            if (!["yaml", "yml"].includes(ext))
                file = "s" + Date.now() + "_" + file;
            const ret = {
                key: '',
                err: 'File not allowed [mp3, jpg, jpeg, png, gif, svg, pdf, yaml, yml]',
            };
            if (typeof ext === 'undefined') {
                return {
                    key: '',
                    err: 'No file extension',
                };
            }
            const fName = file.substring(0, file.length - ext.length - 1);
            ret.err = '';
            ret.key = http.api.getS3Rel(targetFolder, lang) + tools.models.sluggify(fName) + '.' + ext;

            return ret;
        },
        uppyGetConfig(id, targetFolder, allowedTypes = [], maxFiles = 1, language = '') {
            if (!language) language = process.env.VUE_APP_DEFAULT_LANGUAGE;
            const types = [];
            allowedTypes.forEach(t => {
                if (t === tools.models.uploadFileTypes.IMAGE) {
                    types.push('image/*');
                    types.push('.png');
                    types.push('.jpeg');
                    types.push('.jpg');
                    types.push('.gif');
                    types.push('.svg');
                    return;
                }
                if (t === tools.models.uploadFileTypes.PDF) {
                    types.push('.pdf');
                    return;
                }
                if (t === tools.models.uploadFileTypes.YAML) {
                    types.push('.yaml');
                    types.push('.yml');
                    return;
                }
                if (t === tools.models.uploadFileTypes.SOUND) {
                    types.push('.mp3');
                }
                if (t === tools.models.uploadFileTypes.CSV) {
                    types.push('.csv');
                }
            });
            return {
                id: id,
                locale: Italian,
                restrictions: {
                    maxNumberOfFiles: maxFiles,
                    allowedFileTypes: types,
                },
                autoProceed: false,
                onBeforeFileAdded: currentFile => {
                    const ret = this.getS3keyFromFile(
                        currentFile.name,
                        targetFolder,
                        language,
                    );
                    if (ret.err) {
                        return false;
                    }
                    return Object.assign({}, currentFile, {
                        name: ret.key,
                    });
                },

            };
        },
        protectCloudfront: (url, customUrlUpdater = null) => {
            if (process.env.NODE_ENV !== 'production') return url;
            if (!url) return '';
            url = url.replace(process.env.VUE_APP_CLOUDFRONT_OPEN, process.env.VUE_APP_CLOUDFRONT_PROTECTED);
            if (customUrlUpdater && typeof customUrlUpdater === "function"){
                url = customUrlUpdater(url);
            }
            return url;
        },
        uppyFinalizer: () => {
            tools.report('UPPY FINISHED');
        },
        uppyGetUpdater(targetField, protect = false, customUrlUpdater = null) {
            return file => {
                if (file) {
                    const model = this.model;
                    if (file.meta) {
                        let path = http.api.getS3bucket() + file.name;
                        let value;
                        if (protect) {
                            value = this.protectCloudfront(
                                path,
                                customUrlUpdater,
                            );
                        } else {
                            if (customUrlUpdater && typeof customUrlUpdater === "function"){
                                path = customUrlUpdater(path);
                            }
                            value = path;
                        }
                        console.log("MODEL PRE UPDATE", model);
                        if (targetField.includes(".")) {
                            //Allow syntax "audio.url" for deep setting
                            let schema = model;
                            const pieces = targetField.split(".");
                            const loops = pieces.length;
                            for (let i = 0; i<loops-1; i++){
                                let key = pieces[i];
                                if (!schema[key]) schema[key] = {};
                                schema = schema[key];
                            }
                            console.log("Set loop", (loops-1), pieces[loops-1], value, schema, model);
                            schema[pieces[loops-1]] = value;
                        } else {
                            model[targetField] = value;
                        }
                        console.log("MODEL POST UPDATE", targetField, model);

                    }
                }
            };
        },
        uppyInit(targetField, lang, targetFolder, protect, allowedTypes, maxFiles, customUrlSetter) {
            return new Uppy(this.uppyGetConfig(targetField, targetFolder, allowedTypes, maxFiles, lang))
                .use(AwsS3, {
                    getUploadParameters(file) {
                        return http.api.getS3Params(file);
                    },
                })
                .on('upload-success', this.uppyGetUpdater(targetField, protect, customUrlSetter))
                .on('complete', this.uppyFinalizer);
        },
        prepareUploads(uploads, lang){
            const self = this;
            uploads.forEach(upload => {
                self[upload.el] = self.uppyInit(
                    upload.target,
                    lang,
                    upload.folder,
                    upload.protect,
                    upload.types,
                    upload.maxNum,
                    upload.urlChanger);
            });
        },
        resetUploads(uploads){
            const self = this;
            uploads.forEach(upload => {
                self[upload.el].close();
            });
        }
    },
};
